<template>
  <form v-if="student" class="purchase">
    <div class="purchase__toolbar">
      <button
        type="button"
        :disabled="studentStore.loading.studentClassPurchases"
        class="btn btn-outline-primary"
        @click="addClassPurchase"
      >
        <i class="bi bi-plus-lg" /> Add Class Purchase
      </button>
    </div>

    <div class="table_wrapper">
      <table class="app_table table table-striped table-hover">
        <thead class="table-light">
          <tr>
            <th scope="col">
              #
            </th>
            <th class="th_date" scope="col">
              Issued Date
            </th>
            <th class="th_number_of_classes" scope="col">
              Nr. of group classes
            </th>
            <th class="th_number_of_classes" scope="col">
              Nr. of individual classes
            </th>
            <th class="th_number_of_classes" scope="col">
              Used classes
            </th>
            <th class="th_checkbox th_is_flexible" scope="col">
              Flexi Classes
            </th>
            <th scope="col">
              Reference number
            </th>
            <th scope="col">
              Paid Date
            </th>
            <th scope="col">
              Paid Amount
            </th>
            <th scope="col">
              Notes
            </th>
            <th class="th_action th_checkbox" scope="col" />
          </tr>
        </thead>
        <tbody v-if="studentStore.loading.studentClassPurchases">
          <tr>
            <td colspan="11">
              <LoadingSpinner class="my-2" text="Loading Class Purchases..." />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <template v-for="(purchase, index) in student.class_purchases ?? []" :key="index">
            <tr
              class="hover-pointer"
              @click="toggle(purchase)"
            >
              <th scope="row">
                {{ student.class_purchases?.length ?? 0 - index }}
              </th>
              <td class="td_paid_date">
                <VueDatePicker
                  v-model="purchase.invoice_issued_date"
                  class="pause_until"
                  input-class-name="form-control input-sm"
                  placeholder="Select date"
                  :format="formatDate"
                  :enable-time-picker="false"
                  hide-input-icon
                  auto-apply
                  :teleport="true"
                  @click.stop
                />
              </td>
              <td class="td_number_of_classes">
                <SelectInput
                  v-model="purchase.number_of_group_classes"
                  :options="calculateNumberOfGroupClassesOptions(purchase)"
                  :disabled="purchase.is_initial_class"
                  :show-clear-button="false"
                  disable-search
                  @click.stop
                />
              </td>
              <td class="td_number_of_classes">
                <SelectInput
                  v-model="purchase.number_of_individual_classes"
                  :options="calculateNumberOfIndividualClassesOptions(purchase)"
                  :disabled="purchase.is_initial_class"
                  :show-clear-button="false"
                  disable-search
                  @click.stop
                />
              </td>
              <td class="td_number_of_classes">
                {{ purchase.used_classes_count }}/{{ purchase.number_of_classes }}
              </td>
              <td class="td_checkbox td_is_flexible">
                <div
                  v-if="!purchase.is_initial_class"
                  class="form-check"
                >
                  <input
                    v-model="purchase.is_flexible"
                    class="form-check-input d-block"
                    type="checkbox"
                    :disabled="purchase.is_initial_class"
                    @click.stop
                  >
                </div>
              </td>
              <td class="td_reference_number">
                <input
                  v-model="purchase.reference_number"
                  type="text"
                  class="form-control"
                  placeholder="reference num..."
                  @click.stop
                >
              </td>
              <td class="td_paid_date">
                <VueDatePicker
                  v-model="purchase.paid_date"
                  class="pause_until"
                  input-class-name="form-control input-sm"
                  placeholder="Select date"
                  :format="formatDate"
                  :enable-time-picker="false"
                  hide-input-icon
                  auto-apply
                  :teleport="true"
                  @click.stop
                />
              </td>
              <td class="td_paid_amount">
                <div class="input-group" @click.stop>
                  <input
                    v-model="purchase.paid_amount"
                    type="number"
                    class="form-control"
                    min="0"
                  >
                  <span class="input-group-text">€</span>
                </div>
              </td>
              <td class="td_notes">
                <textarea
                  v-model="purchase.notes"
                  class="form-control"
                  rows="1"
                  @click.stop
                />
              </td>
              <td class="td_action hover-pointer">
                <div class="td_action__content">
                  <div
                    v-if="purchase.is_initial_class"
                    class="p-2 bg_our_language_hour badge rounded-pill hover-default"
                  >
                    OLH
                  </div>
                  <i
                    v-else
                    class="bi bi-trash3-fill text-danger remove_item"
                    @click.stop="removePurchase(index)"
                  />
                  <span class="ms-4">
                    <i v-if="purchase.is_open" class="bi bi-chevron-up" />
                    <i v-else class="bi bi-chevron-down" />
                  </span>
                </div>
              </td>
            </tr>
            <!-- Class Attendances -->
            <tr>
              <td colspan="11" class="accordion-collapse collapse" :class="{ show: purchase.is_open }">
                <div class="purchase__attendances">
                  <div
                    v-for="attendance in purchase.attendances"
                    :key="attendance.id"
                    class="purchase__attendance"
                    :class="{'purchase__attendance--no_show': attendance.no_show || attendance.recouped_event}"
                  >
                    <div class="purchase__attendance_date">
                      {{ formatDate(attendance.recouped_event?.start || attendance.event.start, true, false) }}
                      {{ formatTime(attendance.recouped_event?.start || attendance.event.start) }} -
                      {{ formatTime(attendance.recouped_event?.end || attendance.event.end) }}
                      <div class="purchase__attendance_badges">
                        <div
                          class="p-2 bg-primary badge rounded-pill"
                          :class="{'bg-danger': attendance.no_show || attendance.recouped_event}"
                        >
                          {{ getAttendanceText(attendance) }}
                        </div>
                      </div>
                    </div>
                    <div>{{ attendance.recouped_event?.title || attendance.event.title }}</div>
                    <div v-if="attendance.recouped_attendance" class="p-2 badge recouped_badge">
                      Recouped on
                      {{ formatDate(attendance.event.start) }}
                      {{ formatTime(attendance.event.start) }} -
                      {{ formatTime(attendance.event.end) }}<br>
                      {{ attendance.event.title }}
                    </div>
                  </div>

                  <div
                    v-for="attendanceIndex in purchase.unused_group_classes_count"
                    :key="attendanceIndex"
                    class="purchase__attendance purchase__attendance--unused"
                  >
                    Unused
                    <template v-if="purchase.is_initial_class">
                      OLH Attendance
                    </template>
                    <template v-else>
                      <strong>GROUP</strong> Class Attendance
                    </template>
                  </div>
                  <div
                    v-for="attendanceIndex in purchase.unused_individual_classes_count"
                    :key="attendanceIndex"
                    class="purchase__attendance purchase__attendance--unused"
                  >
                    Unused
                    <template v-if="purchase.is_initial_class">
                      OLH Attendance
                    </template>
                    <template v-else>
                      <strong>INDIVIDUAL</strong> Class Attendance
                    </template>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </form>
</template>

<script lang="ts">
import SelectInput from "@/components/ui/input/SelectInput.vue";
import Student from "@/types/models/student";
import ISelectInputChoice, { IInputErrors } from "@/types/input";
import { defineComponent, PropType } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import useDateMixin from "@/mixins/date.mixin";
import StudentClassPurchase from "@/types/models/student_class_purchase";
import LoadingSpinner from "@/components/ui/spinner/LoadingSpinner.vue";
import { IStudentEventAttendanceExtended } from "@/types/student_class_purchase";
import { useStudentStore } from "@/stores/student.store";


export default defineComponent({
  name: "StudentBilling",
  components: { LoadingSpinner, SelectInput },
  mixins: [mappingsMixin],
  props: {
    modelValue: {
      type: Object as PropType<Student>,
      default: () => {
        return {};
      },
    },
    inputErrors: {
      type: Object as PropType<IInputErrors>,
      default: () => {
        return {};
      },
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const studentStore = useStudentStore();
    const { formatDate, formatTime } = useDateMixin();
    return { studentStore, formatDate, formatTime }
  },
  data() {
    const numberOfClassesOptions: ISelectInputChoice[] = Array.from({ length: 13 }, (_, i) => ({
      id: i,
      name: `${i}`,
    }));

    return {
      numberOfClassesOptions: numberOfClassesOptions,
    };
  },
  computed: {
    student: {
      get(): Student {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    "student.class_purchases": {
      handler(classPurchases) {
        for (const classPurchase of classPurchases) {
          classPurchase.number_of_classes = Math.max(classPurchase.number_of_group_classes + classPurchase.number_of_individual_classes, 0);
          classPurchase.unused_classes_count = Math.max(classPurchase.number_of_classes - classPurchase.used_classes_count, 0);
          classPurchase.unused_group_classes_count = Math.max(classPurchase.number_of_group_classes - classPurchase.used_group_classes_count, 0);
          classPurchase.unused_individual_classes_count = Math.max(classPurchase.number_of_individual_classes - classPurchase.used_individual_classes_count, 0);
          console.log(classPurchase);
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.student.id) {
      this.studentStore.getStudentClassPurchases(this.student.id).then(
        (purchases) => {
          this.student.class_purchases = purchases.map(item => {
            return new StudentClassPurchase(item)
          });
        },
      )
    } else {
      // Add Purchase of the Initial Class (Our Language Hour)
      this.student.class_purchases = [
        new StudentClassPurchase({
          student: this.student.id,
          number_of_classes: 1,
          number_of_group_classes: 0,
          number_of_individual_classes: 1,
          is_flexible: true,
          is_initial_class: true,
          created: (new Date()).toISOString().split("T")[0],
        }),
      ]
    }
  },
  methods: {
    calculateNumberOfGroupClassesOptions(purchase: StudentClassPurchase) {
      // Prevent selecting option that is smaller of already used
      // classes count to prevent problems.
      return this.numberOfClassesOptions.filter(
        option => option.id as number >= purchase.used_group_classes_count,
      );
    },
    calculateNumberOfIndividualClassesOptions(purchase: StudentClassPurchase) {
      // Prevent selecting option that is smaller of already used
      // classes count to prevent problems.
      return this.numberOfClassesOptions.filter(
        option => option.id as number >= purchase.used_individual_classes_count,
      );
    },
    toggle(purchase: StudentClassPurchase) {
      purchase.is_open = !purchase.is_open;
    },
    addClassPurchase() {
      const purchase = new StudentClassPurchase({
        student: this.student.id,
        created: (new Date()).toISOString().split("T")[0],
      });

      this.student.class_purchases?.unshift(purchase)
    },
    removePurchase(purchaseIndex: number) {
      this.student.class_purchases?.splice(purchaseIndex, 1);
    },
    getAttendanceText(attendance: IStudentEventAttendanceExtended) {
      if (attendance.no_show || attendance.recouped_event) {
        return "NS"
      } else if (attendance.attended) {
        return "ATT"
      }
      return ""
    },
  },
});
</script>

<style scoped lang="scss">
.purchase {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .table_wrapper {
    overflow: auto;
    height: 100%;
    flex-grow: 1;
    margin-top: 1rem;

    @include sm {
      margin-top: 0;
    }
    @include md {
      height: 600px;
      max-height: 600px;
    }
  }
  table {
    border-collapse: collapse;
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
    tbody tr {
      &:nth-of-type(2n) {
        border-bottom: 0.25rem solid #e8e8e8;
      }

      &:first-of-type {
        border: inherit;
      }
    }
  }
  &__toolbar {
    display: flex;
    justify-content: center;
  }
  .accordion-button,
  .accordion-body {
    padding: 0.75rem;
  }

  &__items {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .td_reference_number {
    min-width: 10rem;
    width: 10rem;
  }
  .td_date {
    min-width: 7rem;
    width: 7rem;
    vertical-align: middle;
  }
  .td_paid_date {
    min-width: 7.5rem;
    width: 7.5rem;
    input {
      text-align: center;
    }
  }
  .td_paid_amount {
    min-width: 8.5rem;
    width: 8.5rem;
    input {
      padding-right: 0.375rem;
    }
  }
  .td_number_of_classes,
  .th_number_of_classes {
    width: 4rem;
    min-width: 4rem;
  }
  .th_checkbox,
  .td_checkbox {
    min-width: 6.5rem;
    width: 6.5rem;
  }

  td {
    vertical-align: middle;
  }
  .td_checkbox {
    .form-check {
      display: flex;
      justify-content: center;
    }
  }
  .td_notes {
    min-width: 14rem;
    textarea {
      resize: vertical;
    }
  }
  .th_action,
  .td_action {
    width: 90px;
    font-size: 1rem;
    padding-right: 1rem;

    &__content {
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }
  }
  .th_is_flexible,
  .td_is_flexible {
    min-width: 4rem;
    width: 4rem;
  }
  &__attendances {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
  }
  &__attendance {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    border: 1px dotted lightgray;
    border-radius: 0.25rem;
    padding: 0.5rem;
    background: $color-attended;
    color: white;
    height: 5.5rem;
  }
  &__attendance--no_show {
    background: $color-no-show;
  }
  &__attendance--unused {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: 2px dotted #b6b6b6;
    color: #7a7a7a;
  }
  &__attendance_date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 600;
  }
  &__attendance_badges {
    display: flex;
    gap: 0.25rem;
  }
  .recouped_badge {
    background: $color-attended;
    text-align: left;
    width: 100%;
    margin-top: 0.25rem;
    line-height: 0.875rem;
  }
}
</style>
